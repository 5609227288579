@media (max-width: 768px) {
  #search {
    width: 50% !important;
  }
  .w-50 {
    width: 100% !important;
  }
  .table{
    margin-top: 8px;
  }
}

.form-control:focus {
  border-color: #4aba70;
}

#header {
  width: 100%;
}

.table th {
  font-size: 14px;
}
.table td {
  font-size: 12px;
}

/* Login header styling */
.login-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Logo image styling */
.login-logo {
  width: 200px !important; /* Adjust width as needed */
  height: auto; /* Maintain aspect ratio */
}

/* Login title styling */
.login-title {
  font-size: 24px; /* Font size for title */
  font-weight: bold;
  color: #333; /* Dark text color */
}

/* Spinner container styling */
.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
} 

/* Custom red button styles */
.btn-red {
  background-color: #e74c3c !important; /* Red background color */
  color: white !important; /* White text color */
  border: none; /* Remove border */
  border-radius: 5px !important; /* Slightly rounded corners */
  font-weight: bold !important; /* Bold text */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.btn-red:hover {
  background-color: #c0392b !important; /* Darker red on hover */
}
/* Custom styles for the navigation bar */
.header-navbar {
  background-color: #ffffff; /* White background */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
  padding: 10px 20px; /* Padding for spacing */
}

.header-navbar .navbar-brand img {
  width: 100px; /* Adjust logo size */
}

.header-navbar .nav-link {
  font-size: 1.2rem;
  color: #000; /* Black text color */
  padding: 10px 15px; /* Spacing */
  border-radius: 5px; /* Rounded corners */
  margin-right: 15px;
  transition: background-color 0.3s ease-in-out; /* Smooth transition on hover */
  text-decoration: none; /* Hide underline */
}

.header-navbar .nav-link:hover {
  background-color: #c82333; /* Light gray on hover */
  color: #fff; /* Bootstrap primary color on hover */
  text-decoration: none; /* Hide underline */
}

.header-navbar .logout-button {
  color: #ffffff;
  background-color: #dc3545; /* Bootstrap danger color */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
}

.header-navbar .logout-button:hover {
  background-color: #c82333; /* Darker red on hover */
}

.header-navbar .nav-link-icon {
  margin-right: 8px; /* Spacing between icon and text */
}


/* Custom styles.css or App.css */


/* Hide buttons on larger screens and when components are active */
@media (min-width: 768px) {
  .mobile-only {
    display: none;
  }
}

/* Style buttons for mobile view */
.mobile-button-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.mobile-button {
  flex: 1; /* Each button takes up half the width of the container */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  margin: 5px;
  border: none;
  border-radius: 10px;
  background-color: #d52031; /* Updated button color */
  color: white;
  font-size: 18px;
  box-shadow: 0 4px 8px rgba(213, 32, 49, 0.3);
}

.mobile-button-icon {
  margin-right: 8px;
}
